import React, { useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Divider,
} from "@mui/material";
import {
  Folder,
  MoreVert,
  Share,
  MoveToInbox,
  Download,
  OpenInBrowser,
  ArrowForward,
  Edit,
  NotInterested,
} from "@mui/icons-material";
import { useTheme } from "../style/theme";

const Catelog = ({ items }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [subMenuOpen, setSubMenuOpen] = useState(null); // Manage the open submenus

  const handleMenuOpen = (event, item) => {
    setAnchorEl(event.currentTarget);
    setSelectedItem(item);
    setSubMenuOpen(null); // Close any open submenus when opening a new menu
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedItem(null);
    setSubMenuOpen(null); // Close any open submenus
  };

  const isMenuOpen = Boolean(anchorEl);
  const { mode } = useTheme();

  const menuOptions = [
    {
      label: "Open With",
      icon: <OpenInBrowser />,
      subMenu: [
        {
          label: "Google Docs",
          action: () => console.log("Open with Google Docs"),
        },
        {
          label: "Microsoft Word",
          action: () => console.log("Open with Microsoft Word"),
        },
      ],
    },
    {
      label: "Download",
      icon: <Download />,
      action: () => console.log("Download", selectedItem),
    },
    {
      label: "Rename",
      icon: <Edit />,
      action: () => console.log("Rename", selectedItem),
    },
    {
      label: "Move to Trash",
      icon: <MoveToInbox />,
      action: () => console.log("Move to Trash", selectedItem),
    },
    {
      label: "Not Helpful",
      icon: <NotInterested />,
      action: () => console.log("Not Helpful", selectedItem),
    },
    {
      label: "Share",
      icon: <Share />,
      subMenu: [
        {
          label: "Share with Link",
          action: () => console.log("Share with Link"),
        },
        {
          label: "Share via Email",
          action: () => console.log("Share via Email"),
        },
      ],
    },
    {
      label: "Organize",
      icon: <ArrowForward />,
      subMenu: [
        {
          label: "Move to Folder",
          action: () => console.log("Move to Folder"),
        },
        { label: "Add to Drive", action: () => console.log("Add to Drive") },
      ],
    },
    {
      label: "Folder Information",
      icon: <Folder />,
      action: () => console.log("Folder Information", selectedItem),
    },
  ];

  const handleSubMenuToggle = (item) => {
    if (subMenuOpen === item) {
      setSubMenuOpen(null); // Close submenu if it's already open
    } else {
      setSubMenuOpen(item); // Open submenu
    }
  };

  return (
    <Box
      display="grid"
      gridTemplateColumns="repeat(auto-fit, minmax(15rem, 1fr))"
      gap="1rem"
      padding="1rem"
      width={"100%"}
    >
      {items.map((item, index) => (
        <Box
          padding="1rem"
          key={index}
          display="flex"
          alignItems="center"
          justifyContent={"space-between"}
          sx={{
            backgroundColor: mode === "light" ? "white" : "#1E1E1E",
            border: `2px solid ${mode === "light" ? "#e5e7eb" : "transparent"}`,
            borderRadius: "0.5rem",
            ":hover": {
              backgroundColor: mode === "light" ? "#f7f7f7" : "#2E2E2E",
            },
          }}
        >
          <Box width="100%" display="flex" gap={"1rem"} alignItems={"center"} justifyContent={'space-between'}>
            <Box display={'flex'} gap={'1rem'}  width={'100%'}>
              <Box>
                {item.clikkle ? (
                  <Folder style={{ fontSize: "1.5rem", color: "#488BF1" }} />
                ) : (
                  <Folder style={{ fontSize: "1.5rem", color: "#FFCA28" }} />
                )}
              </Box>
              <Box
                display={"flex"}
                gap={"0rem"}
                flexDirection={"column"}
                width={"50%"}
              >
                <Typography
                  fontFamily="sans-serif"
                  fontWeight="500"
                  whiteSpace={"nowrap"}
                  textOverflow={"ellipsis"}
                  overflow={"hidden"}
                  sx={{
                    color: `${
                      mode === "light" ? "rgba(0, 0, 0, 0.6)" : "#fff"
                    } `,
                  }}
                  fontSize={"13px"}
                >
                  {item.name}
                </Typography>
                <Typography
                  fontFamily="sans-serif"
                  fontWeight="500"
                  sx={{
                    color: `${
                      mode === "light" ? "rgba(0, 0, 0, 0.6)" : "#fff"
                    } `,
                  }}
                  fontSize={"10px"}
                >
                  {item.location}
                </Typography>
              </Box>
            </Box>
            <Box display={"flex"}>
              <IconButton
                onClick={(e) => handleMenuOpen(e, item)}
                sx={{ color: "#A5A5A5" }}
              >
                <MoreVert style={{ fontSize: 30 }} />
              </IconButton>
            </Box>
          </Box>
        </Box>
      ))}

      {/* Menu for More Options */}
      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {menuOptions.map((option, idx) => {
          // Render menu items
          const menuItems = [];

          // Regular menu item
          menuItems.push(
            <MenuItem
              key={idx}
              onClick={() => {
                if (option.subMenu) {
                  handleSubMenuToggle(option); // Toggle submenu open/close
                } else {
                  option.action && option.action();
                  handleMenuClose();
                }
              }}
            >
              {option.icon}
              <Typography sx={{ ml: 2 }}>{option.label}</Typography>
              {option.subMenu && <ArrowForward sx={{ ml: "auto" }} />}{" "}
              {/* Right arrow for submenus */}
            </MenuItem>
          );

          // Submenu items
          if (option.subMenu && subMenuOpen === option) {
            option.subMenu.forEach((subOption, subIdx) => {
              menuItems.push(
                <MenuItem key={subIdx} onClick={subOption.action}>
                  <Typography sx={{ ml: 4 }}>{subOption.label}</Typography>
                </MenuItem>
              );
            });
          }

          // Add Divider if necessary
          if (idx === 2 || idx === 6) {
            menuItems.push(<Divider key={`divider-${idx}`} />);
          }

          return menuItems;
        })}
      </Menu>
    </Box>
  );
};

export default Catelog;
