import * as React from "react";
import { Dialog, DialogContent, Slide, Box, Typography } from "@mui/material";
import { Folder } from "@mui/icons-material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { slideMenuOptions } from "./Constants/DummyData";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SlideUpMenu({ toggle, setToggle, item }) {
  const handleClose = () => {
    setToggle(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={toggle}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperProps={{
          sx: {
            position: "fixed",
            bottom: 0,
            margin: 0,
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
            width: "100%",
            height:'35rem'
          },
        }}
      >
        <DialogContent
          sx={{
            padding: "0px",
            height:'20rem'
          }}
        >
          <Box display={'flex'} flexDirection={'column'}>
            <Box
              display={"flex"}
              gap={"1rem"}
              borderBottom={"1px solid gray"}
              width={"100%"}
              padding={"1rem"}
            >
              {item.clikkle && item.type === "folder" && (
                <Folder style={{ fontSize: 20, color: "#488BF1" }} />
              )}
              {!item.clikkle && item.type === "folder" && (
                <Folder style={{ fontSize: 20, color: "#FFCA28" }} />
              )}

              {item.type === "file" && (
                <InsertDriveFileIcon
                  style={{ fontSize: 20, color: "#FFCA28" }}
                />
              )}
              <Typography>{item.name}</Typography>
            </Box>
            {slideMenuOptions.map((option, i) => (
              <Box display={'flex'} gap={'1rem'} padding={'1rem'} key={i}>
                <Box>{option.icon}</Box>
                <Box>
                  <Typography>{option.label}</Typography>
                </Box>
              </Box>
            ))}
            </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
