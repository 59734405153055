import {
  Box,
  Button,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  IconButton,
} from "@mui/material";
import React, { useState } from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Catelog from "../components/Catelog";
import {
  ClikkleItems,
  RandomClikkleItems,
} from "../components/Constants/DummyData";
import ListIcon from "@mui/icons-material/ViewList";
import GridViewIcon from "@mui/icons-material/GridView";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import ListFilesFolder from "../components/ListFilesFolder";
import TileFilesFolder from "../components/TileFilesFolder";
import FloatingActionButton from "../components/FloatingActionButton";
import Filters from "../components/Filters";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useMediaQuery } from "@mui/material";
import ListMobileView from "../components/ListMobileView";
import FilesPic from "../assets/files.png";
import FloatingActions from "../components/FloatingActions";

const Home = () => {
  const [suggestedFolder, setSuggestedFolder] = useState(true);
  const [suggestedFile, setSuggestedFile] = useState(true);
  const [view, setView] = useState("list");

  const handleViewChange = (event, newView) => {
    console.log(newView);
    if (newView !== null) setView(newView);
  };

  const isMobile = useMediaQuery("(max-width:600px)");
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tableHeadings = {
    name: true,
    owner: true,
    location: true,
    size: false,
    reason: true,
  };
  
  if (isMobile) {
    return (
      <Box sx={{ width: "100%", typography: "body1" }} height={"100%"}>
        <FloatingActions/>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="lab API tabs example"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              "& .MuiTabs-flexContainer": {
                justifyContent: "space-around",
              },
            }}
          >
            <Tab label="Suggested" value="1" />
            <Tab label="Activity" value="2" />
          </Tabs>
        </Box>
        {value === "1" && (
          <Box
            sx={{
              padding: "0.5rem",
              height: "calc(100% - 4rem)",
              overflow: "scroll",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1rem",
                }}
              >
                Files
              </Typography>
              <Box>
                {view === "folder" ? (
                  <IconButton onClick={() => handleViewChange(null, "list")}>
                    <GridViewIcon />
                  </IconButton>
                ) : (
                  <IconButton onClick={() => handleViewChange(null, "folder")}>
                    <ListIcon />
                  </IconButton>
                )}
              </Box>
            </Box>
            <Box padding={"0.5rem"}>
              {view === "list" ? (
                <ListMobileView
                  items={[...RandomClikkleItems, ...RandomClikkleItems]}
                />
              ) : (
                <TileFilesFolder
                  items={[...RandomClikkleItems, ...RandomClikkleItems]}
                  gridStyle={false}
                />
              )}
            </Box>
          </Box>
        )}
        {value === "2" && (
          <Box
            value="2"
            sx={{
              padding: "1rem",
              height: "calc(100% - 10rem)",
            }}
          >
            <Box
              display={"flex"}
              height={"100%"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Box
                component="img"
                src={FilesPic} // Replace this with the path to your image
                alt="No files illustration"
                sx={{ width: "50%", maxWidth: "300px", marginBottom: "1rem" }}
              />
              <Typography
                sx={{
                  fontSize: "1rem",
                  textAlign: "center",
                }}
              >
                Oops! You don’t have any files.
                <br />
                When you add files, or files are shared with you, they will
                appear here.
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    );
  }

  return (
    <Box p={2}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems="center"
        width="100%"
        gap="1rem"
        sx={{
          height: "calc(100vh - 8rem)",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            display: "none", // For Chrome, Safari, and Edge
          },
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          width={"100%"}
          gap={"1rem"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography fontSize={"2rem"}>Welcome to Files</Typography>
          <Filters leftImg={true} />
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          width={"100%"}
          alignItems={"center"}
        >
          <Button
            startIcon={
              suggestedFolder ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />
            }
            onClick={() => {
              setSuggestedFolder(!suggestedFolder);
            }}
          >
            Suggested Folder
          </Button>
        </Box>
        {suggestedFolder && <Catelog items={ClikkleItems} />}
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          width={"100%"}
          alignItems={"center"}
        >
          <Button
            startIcon={
              suggestedFile ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />
            }
            onClick={() => {
              setSuggestedFile(!suggestedFile);
            }}
          >
            Suggested File
          </Button>
          {suggestedFile && (
            <Box display={"flex"} justifyContent={"space-between"}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="end"
                alignItems="end"
                width="100%"
                gap={"1rem"}
              >
                <ToggleButtonGroup
                  value={view}
                  exclusive
                  onChange={handleViewChange}
                  aria-label="view selection"
                >
                  <ToggleButton
                    value="list"
                    aria-label="list view"
                    sx={{
                      color: "#3767B1",
                      paddingX: "1rem",
                      border: "2px solid #3B84D9",
                      borderRadius: "20rem",
                      "&.Mui-selected": {
                        color: "white",
                        backgroundColor: "#3B84D9",
                      },
                    }}
                  >
                    <ListIcon fontSize="1rem" />
                  </ToggleButton>
                  <ToggleButton
                    value="folder"
                    aria-label="folder view"
                    sx={{
                      color: "#3767B1",
                      paddingX: "1rem",
                      border: "2px solid #3B84D9",
                      borderRadius: "20rem",
                      "&.Mui-selected": {
                        color: "white",
                        backgroundColor: "#3B84D9",
                      },
                    }}
                  >
                    {view === "folder" ? (
                      <GridViewRoundedIcon fontSize="1rem" />
                    ) : (
                      <GridViewIcon fontSize="1rem" />
                    )}
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </Box>
          )}
        </Box>
        {suggestedFile && view === "list" && (
          <Box display={"flex"} width={"100%"}>
            <Box
              display="flex"
              flexDirection="column"
              gap="1rem"
              width={"100%"}
            >
              <ListFilesFolder
                items={RandomClikkleItems}
                tableHeadings={tableHeadings}
              />
            </Box>
          </Box>
        )}
        {suggestedFile && view === "folder" && (
          <Box display="flex" flexDirection="column" width={"100%"} gap="1rem">
            <TileFilesFolder items={RandomClikkleItems} />
          </Box>
        )}
      </Box>
      <FloatingActionButton />
    </Box>
  );
};

export default Home;
