import React from "react";
import { Route, Routes } from "react-router-dom";
import "./utilities/axios";

// Pages and Components
import Header from "./components/Header";
import {
  Home,
  Favorite,
  Trash,
  Recent,
  SharedByMe,
  SharedWithMe,
  MyFiles,
} from "./pages";
import BottomNavBar from "./components/BottomNavBar";

const App = () => {
  return (
    <>
      <Header>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/my-files" element={<MyFiles />} />
          <Route path="/favorite" element={<Favorite />} />
          <Route path="/recent" element={<Recent />} />
          <Route path="/trash" element={<Trash />} />
          <Route path="/shared-by-me" element={<SharedByMe />} />
          <Route path="/shared-with-me" element={<SharedWithMe />} />
        </Routes>
          <BottomNavBar />
      </Header>
    </>
  );
};

export default App;
