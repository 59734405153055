import React, { useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  ListItemIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  Folder,
  AccountCircle,
  FolderOutlined,
  MoreVert,
  Share,
  OpenInNew,
  FileDownload,
  Delete,
  Create,
  FileCopy,
} from "@mui/icons-material";
import {  
  
  Dialog, 
  DialogTitle, 
  DialogContent, 
  TextField,  
  Button, 
  Avatar 
} from "@mui/material";
import { Link, ArrowDropDown, Close } from "@mui/icons-material";
import ProfileIcon from '../../src/assets/Icons/profile_temp.svg'
import ProfileIconOne from '../../src/assets/Icons/profile_temp1.svg'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import PeopleIcon from "@mui/icons-material/People";  
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { useTheme } from "../style/theme";
import { useUser } from '../hooks/Authorize';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';


const ListFilesFolder = ({ items, tableHeadings }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [submenuOpen, setSubmenuOpen] = useState(null); // Tracks which submenu is open
  const [openModal, setOpenModal] = useState(false);
  const { mode } = useTheme();

  const handleMenuOpen = (event, item) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSubmenuOpen(null); // Close submenu on menu close
  };
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleSubMenuToggle = (submenu) => {
    setSubmenuOpen(submenu === submenuOpen ? null : submenu); // Toggle submenu visibility
  };
  const platformUser = useUser();

  const isMenuOpen = Boolean(anchorEl);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow
            sx={{
              borderBottom: `2px solid ${
                mode === "light" ? "#e5e7eb" : "rgba(21, 21, 21, 1)"
              }`,
            }}
          >
            {tableHeadings.name && (
              <TableCell padding="none" sx={{ height: "100%" }}>
                Name
              </TableCell>
            )}
                        {tableHeadings.reason && (
              <TableCell padding="none" sx={{ height: "100%" }}>
                Reason
              </TableCell>
            )}
            {tableHeadings.owner && (
              <TableCell padding="none" sx={{ height: "100%" }}>
                Owner
              </TableCell>
            )}
            {tableHeadings.location && (
              <TableCell padding="none" sx={{ height: "100%" }}>
                Location
              </TableCell>
            )}
            {tableHeadings.last_modified && (
                            <TableCell padding="none" sx={{ height: "100%" }}>
                            Last Modified
                          </TableCell>
            )}

            {tableHeadings.size && (
              <TableCell padding="none" sx={{ height: "100%" }}>
                Size
              </TableCell>
            )}
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item, i) => (
            <TableRow
              key={i}
              sx={{
                borderBottom: `2px solid ${
                  mode === "light" ? "#e5e7eb" : "rgba(21, 21, 21, 1)"
                }`,
                "&:hover .additional-icons": { visibility: "visible" },
                "&:hover": {
                  backgroundColor: "custom.search.focus",
                },
              }}
            >
              {tableHeadings.name && (
                <TableCell padding="none" sx={{ height: "100%" }}>
                  <Box display="flex" alignItems="center" gap="1rem">
                    {item.clikkle && item.type === "folder" && (
                      <Folder style={{ fontSize: 20, color: "#488BF1" }} />
                    )}
                    {!item.clikkle && item.type === "folder" && (
                      <Folder style={{ fontSize: 20, color: "#FFCA28" }} />
                    )}

                    {item.type === "file" && (
                      <InsertDriveFileIcon
                        style={{ fontSize: 20, color: "#FFCA28" }}
                      />
                    )}
                    <Typography
                      variant="body1"
                      whiteSpace={'nowrap'}
                      sx={{
                        color: `${
                          mode === "light" ? "rgba(0, 0, 0, 0.6)" : "#fff"
                        } `,
                        fontSize: "1rem",
                      }}
                    >
                      {item.name}
                    </Typography>
                  </Box>
                </TableCell>
              )}
                            {tableHeadings.reason && (
                <TableCell padding="none" sx={{ height: "100%" }}>
                  <Box display="flex" alignItems="center" gap="1rem">
                    <Typography
                      variant="body1"
                      whiteSpace={'nowrap'}
                      sx={{
                        color: `${
                          mode === "light" ? "rgba(0, 0, 0, 0.6)" : "#fff"
                        } `,
                      }}
                    >
                      {item.reason}
                    </Typography>
                  </Box>
                </TableCell>
              )}

              {tableHeadings.owner && (
                <TableCell padding="none" sx={{ height: "100%" }}>
                  <Box display="flex" alignItems="center" gap="1rem">
                    <AccountCircle style={{ fontSize: 30, color: "gray" }} />
                    <Typography
                      variant="body1"
                      whiteSpace={'nowrap'}
                      sx={{
                        color: `${
                          mode === "light" ? "rgba(0, 0, 0, 0.6)" : "#fff"
                        } `,
                        fontSize: "1",
                      }}
                    >
                      {item.owner}
                    </Typography>
                  </Box>
                </TableCell>
              )}

              {tableHeadings.location && (
                <TableCell padding="none" sx={{ height: "100%" }}>
                  <Box display="flex" alignItems="center" gap="1rem">
                    <FolderOutlined
                      style={{ fontSize: 35, color: "#A5A5A5" }}
                    />
                    <Typography
                      variant="body1"
                      whiteSpace={'nowrap'}
                      sx={{
                        color: `${
                          mode === "light" ? "rgba(0, 0, 0, 0.6)" : "#fff"
                        } `,
                      }}
                    >
                      {item.location}
                    </Typography>
                  </Box>
                </TableCell>
              )}

{tableHeadings.last_modified && (
                <TableCell padding="none" sx={{ height: "100%" }}>
                  <Box display="flex" alignItems="center" gap="1rem">
                    <Typography
                      variant="body1"
                      whiteSpace={'nowrap'}
                      sx={{
                        color: `${
                          mode === "light" ? "rgba(0, 0, 0, 0.6)" : "#fff"
                        } `,
                      }}
                    >
                      {item.last_modified}
                    </Typography>
                  </Box>
                </TableCell>
              )}



              {tableHeadings.size && (
                <TableCell padding="none" sx={{ height: "100%" }}>
                  <Box display="flex" alignItems="center" gap="1rem">
                    <Typography
                      variant="body1"
                      whiteSpace={'nowrap'}
                      sx={{
                        color: `${
                          mode === "light" ? "rgba(0, 0, 0, 0.6)" : "#fff"
                        } `,
                      }}
                    >
                      {item.type === 'file' ?item.size:'—'}
                    </Typography>
                  </Box>
                </TableCell>
              )}

              {/* Actions Column */}
              <TableCell padding="none" sx={{ height: "100%" }}>
                <Box
                  display="flex"
                  alignItems="center"
                  gap="1rem"
                  justifyContent={"end"}
                >
                  <Box
                    className="additional-icons"
                    sx={{
                      display: "flex",
                      gap: "10px",
                      visibility: "hidden",
                      transition: "visibility 0s, opacity 0.3s linear",
                    }}
                  >
                    <IconButton>
                      <PeopleIcon
                        style={{
                          fontSize: 20,
                          color: `${
                            mode === "light" ? "rgba(0, 0, 0, 0.6)" : "#fff"
                          } `,
                        }}
                      />
                    </IconButton>
                    <IconButton>
                      <CloudDownloadIcon
                        style={{
                          fontSize: 20,
                          color: `${
                            mode === "light" ? "rgba(0, 0, 0, 0.6)" : "#fff"
                          } `,
                        }}
                      />
                    </IconButton>
                    <IconButton>
                      <FavoriteIcon
                        style={{
                          fontSize: 20,
                          color: `${
                            mode === "light" ? "rgba(0, 0, 0, 0.6)" : "#fff"
                          } `,
                        }}
                      />
                    </IconButton>
                    <IconButton>
                      <FileCopyIcon
                        style={{
                          fontSize: 20,
                          color: `${
                            mode === "light" ? "rgba(0, 0, 0, 0.6)" : "#fff"
                          } `,
                        }}
                      />
                    </IconButton>
                  </Box>

                  <IconButton
                    onClick={(e) => handleMenuOpen(e, item)}
                    sx={{ color: "#A5A5A5" }}
                  >
                    <MoreVert style={{ fontSize: 30 }} />
                  </IconButton>

                  {/* Menu */}
                  <Menu
                    anchorEl={anchorEl}
                    open={isMenuOpen}
                    onClose={handleMenuClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    PaperProps={{
                      sx: { width: "250px" }, // Increased width of the menu
                    }}
                  >
                    <MenuItem onClick={() => handleSubMenuToggle("openWith")}>
                      <ListItemIcon>
                        <OpenInNew fontSize="small" />
                      </ListItemIcon>
                      Open With
                    </MenuItem>
                    {submenuOpen === "openWith" && (
                      <Box>
                        <MenuItem onClick={() => console.log("Open With File")}>
                          App 1
                        </MenuItem>
                        <MenuItem onClick={() => console.log("Open With File")}>
                          App 2
                        </MenuItem>
                      </Box>
                    )}
                    <Divider />
                    <MenuItem onClick={() => console.log("Download")}>
                      <ListItemIcon>
                        <FileDownload fontSize="small" />
                      </ListItemIcon>
                      Download
                    </MenuItem>
                    <MenuItem onClick={() => console.log("Rename")}>
                      <ListItemIcon>
                        <Create fontSize="small" />
                      </ListItemIcon>
                      Rename
                    </MenuItem>
                    <MenuItem onClick={() => console.log("Make a Copy")}>
                      <ListItemIcon>
                        <FileCopy fontSize="small" />
                      </ListItemIcon>
                      Make a Copy
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={() => console.log("Move to Trash")}>
                      <ListItemIcon>
                        <Delete fontSize="small" />
                      </ListItemIcon>
                      Move to Trash
                    </MenuItem>
                    <MenuItem onClick={() => console.log("Not Helpful")}>
                      <ListItemIcon>
                        <Delete fontSize="small" />
                      </ListItemIcon>
                      Not Helpful
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={handleOpenModal}>
                      <ListItemIcon>
                        <Share fontSize="small" />
                      </ListItemIcon>
                      Share
                    </MenuItem>
                    {/* {submenuOpen === "share" && (
                      <Box>
                        <MenuItem onClick={handleOpenModal}>
                          Invite People
                        </MenuItem>
                        <MenuItem onClick={() => console.log("Copy link")}>
                          Copy Link
                        </MenuItem>
                      </Box>
                    )} */}
                    <Dialog 
                      open={openModal} 
                      onClose={handleCloseModal} 
                      maxWidth="sm" 
                      fullWidth
                      sx={{
                        "& .MuiBackdrop-root": {
                          backgroundColor: "rgba(0, 0, 0, 0)", // Light transparent overlay
                        },
                        
                      }}
                      >
                      <DialogTitle 
                        sx={{ 
                          display: "flex", 
                          justifyContent: "space-between", 
                          alignItems: "center", 
                          fontSize: "18px" 
                        }}
                      >
                        Share '{item.name}'
                        <Box sx={{ display: "flex", gap: 1 }}>
                          {/* <IconButton>
                            <HelpOutline />
                          </IconButton> */}
                          <Button
                            startIcon={<Link sx={{
                              transform: "rotate(135deg)"
                            }}/>}
                            sx={{  color: "rgb(55, 103, 177)" }}
                            onClick={() => console.log("Copy link clicked")}
                          >
                            Copy link
                          </Button>
                          <IconButton onClick={handleCloseModal}>
                            <Close />
                          </IconButton>
                        </Box>
                      </DialogTitle>
                      <Divider/>
        
                      <DialogContent>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,  // Adjust spacing between elements
                          marginBottom: "20px",
                          marginLeft: '-8px'
                        }}
                      >
                        <TextField
                          
                          placeholder="Add people, groups and calendar event"
                          
                          sx={{
                            flex: 1,  
                            maxWidth: "90%", 
                            width: "90%", 
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "10px",
                              height: "40px", 
                              "& fieldset": {
                                borderColor: "#424242",
                              },
                            },
                            "& .MuiInputBase-input": {
                              padding: "8px 14px", 
                              color: 'rgb(86, 86, 86)'
                            },
                          }}
                        />
                        <Button 
                          variant="contained" 
                          onClick={handleCloseModal}
                          sx={{ 
                            width: "100px", 
                            backgroundColor: "rgb(55, 103, 177)", color: "white", borderRadius: "10px",
                            whiteSpace: "nowrap",
                            marginTop: '-18px'
                          }}
                        >
                          Add
                        </Button>
                      </Box>
        
                      <Typography variant="subtitle1" sx={{ fontWeight: "bold", marginBottom: 1 }}>
                        Who has access
                      </Typography>
                      <Box 
                        sx={{ 
                          display: "flex", 
                          alignItems: "center", 
                          gap: 2, 
                          marginBottom: "20px" ,
                
                        }}
                      >
                        <Avatar sx={{ width: 32, height: 32 }} src={ProfileIcon}/>
                        <Box>
                          <Typography variant="body1">{platformUser.firstName} {" "}{platformUser.lastName}{" "}(you)</Typography>
                          <Typography variant="body2" color="text.secondary">{item.owner}</Typography>
                        </Box>
                        <Typography sx={{ marginLeft: "auto" }}>Owner</Typography>
                      </Box>
                      <Box 
                        sx={{ 
                          display: "flex", 
                          alignItems: "center", 
                          gap: 2, 
                          marginBottom: "20px" ,
                
                        }}
                        >
                        <Avatar sx={{ width: 32, height: 32 }} src={ProfileIconOne}/>
                        <Box>
                          <Typography variant="body1">{platformUser.firstName} {" "}{platformUser.lastName}</Typography>
                          <Typography variant="body2" color="text.secondary">{item.owner}</Typography>
                        </Box>
                        {/* <Typography sx={{ marginLeft: "auto", fontWeight: "bold" }}>Owner</Typography> */}
                        <Box sx={{display:"flex", marginLeft: "auto", gap: 1}}>
                          <Typography>Can View</Typography>
                          <KeyboardArrowDownOutlinedIcon />
                        </Box>
                      </Box>
                      <Box 
                        sx={{ 
                          display: "flex", 
                          alignItems: "center", 
                          gap: 2, 
                          marginBottom: "20px" ,
                
                        }}
                      >
                        <Avatar sx={{ width: 32, height: 32 }} src={ProfileIcon}/>
                        <Box>
                          <Typography variant="body1">{platformUser.firstName} {" "}{platformUser.lastName}</Typography>
                          <Typography variant="body2" color="text.secondary">{item.owner}</Typography>
                        </Box>
                        {/* <Typography sx={{ marginLeft: "auto", fontWeight: "bold" }}>Owner</Typography> */}
                        <Box sx={{display:"flex", marginLeft: "auto", gap: 1}}>
                          <Typography >Can View</Typography>
                          <KeyboardArrowDownOutlinedIcon/>
                        </Box>
                      </Box>
        
                        <Typography variant="subtitle1" sx={{ fontWeight: "bold", marginBottom: 1 }}>
                          General access
                        </Typography>
                        {/* <Box>
                            <Typography variant="subtitle1" sx={{ fontWeight: "bold", marginBottom: 1 }}>John Doe</Typography>
                            <Typography variant="subtitle1" sx={{ fontWeight: "bold", marginBottom: 1 }} color="text.secondary">johndoe@example.com</Typography>
                          </Box> */}
                        <Box 
                          sx={{ 
                            display: "flex", 
                            alignItems: "center", 
                            gap: 2, 
                            // border: "1px solid lightgray", 
                            // borderRadius: "8px", 
                            padding: "8px", 
                            marginBottom: "20px" 
                          }}
                        >
                          {/* <HttpsOutlinedIcon sx={{ color: "lightgray" }} /> */}
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "40px",  
                              height: "40px", 
                              borderRadius: "50%", 
                              backgroundColor: "rgb(59,59,59)", 
                              marginLeft: "-10px"
                            }}
                          >
                            <HttpsOutlinedIcon sx={{ color: "white" }} />
                          </Box>
                          {/* <Typography>Restricted</Typography> */}
                          <Box>
                            {/* <Typography variant="body1">Restricted</Typography> */}
                            <Box sx={{display: "flex", alignItems: "center", gap: 1}}>
                              <Typography variant="body1">Restricted</Typography>
                              <Typography><ArrowDropDown sx={{ color: "white", cursor: "pointer" }} /></Typography>
                            </Box>
                            <Typography variant="body2" color="text.secondary">Only people with access can open with the link</Typography>
                            
                          </Box>
                          <IconButton sx={{ marginLeft: "auto" }}>
                            {/* <Search fontSize="small" /> */}
                          </IconButton>
                        </Box>
        
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "end", marginLeft:"80%" }}>
                          
                          <Button 
                            variant="contained" 
                            onClick={handleCloseModal}
                            sx={{ width: "100px", backgroundColor: "rgb(55, 103, 177)", color: "white", borderRadius: "10px", }}
                          >
                            Done
                          </Button>
                        </Box>
                      </DialogContent>
                    </Dialog>
                    <MenuItem onClick={() => console.log("Organize")}>
                      <ListItemIcon>
                        <FolderOutlined fontSize="small" />
                      </ListItemIcon>
                      Organize
                    </MenuItem>
                    <MenuItem onClick={() => console.log("Folder Information")}>
                      <ListItemIcon>
                        <FolderOutlined fontSize="small" />
                      </ListItemIcon>
                      Folder Information
                    </MenuItem>
                  </Menu>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ListFilesFolder;
