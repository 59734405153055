import React, { useState } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import { Folder } from "@mui/icons-material";
import { useTheme } from "../style/theme";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import SlideUpMenu from "./SlideUpMenu";

const ListMobileView = ({ items }) => {
  const [toggleSlideMenu, setToggleSlideMenu] = useState(false);
  const { mode } = useTheme();
  const [currentItem, setCurrentItem] = useState({});
  return (
    <Box
      display="grid"
      gridTemplateColumns="repeat(auto-fit, minmax(15rem, 1fr))"
      gap="0.5rem"
      width={"100%"}
    >
      {items.map((item, index) => (
        <Box
          key={index}
          display="flex"
          alignItems="center"
          justifyContent={"space-between"}
          sx={{
            border: `2px solid ${mode === "light" ? "#e5e7eb" : "transparent"}`,
            borderRadius: "0.5rem",
          }}
        >
          <Box width="100%" display="flex" gap={"1rem"} alignItems={"center"}>
            {item.clikkle && item.type === "folder" && (
              <Folder style={{ fontSize: 20, color: "#488BF1" }} />
            )}
            {!item.clikkle && item.type === "folder" && (
              <Folder style={{ fontSize: 20, color: "#FFCA28" }} />
            )}

            {item.type === "file" && (
              <InsertDriveFileIcon style={{ fontSize: 20, color: "#FFCA28" }} />
            )}
            <Box display={"flex"} gap={"0rem"} flexDirection={"column"}>
              <Typography
                variant="body"
                fontFamily="sans-serif"
                fontWeight="500"
                sx={{
                  color: `${mode === "light" ? "rgba(0, 0, 0, 0.6)" : "#fff"} `,
                  whiteSpace: "nowrap",
                }}
                fontSize={"13px"}
              >
                {item.name}
              </Typography>
              <Box display={"flex"} alignItems={"center"} gap={"0.5rem"}>
                <PeopleAltIcon
                  fontSize="0.1rem"
                  sx={{
                    color: `${
                      mode === "light" ? "rgba(0, 0, 0, 0.6)" : "#A5A5A5"
                    } `,
                  }}
                />
                <Typography
                  variant="body"
                  fontFamily="sans-serif"
                  fontWeight="500"
                  sx={{
                    color: `${
                      mode === "light" ? "rgba(0, 0, 0, 0.6)" : "#A5A5A5"
                    } `,
                  }}
                  fontSize={"10px"}
                  display={"flex"}
                  alignItems={"center"}
                >
                  {item.reason}
                </Typography>
              </Box>
            </Box>
          </Box>
          <IconButton
            sx={{ color: "#A5A5A5" }}
            onClick={() => {
              setCurrentItem(item);
              setToggleSlideMenu(true);
              console.log("toggle active", toggleSlideMenu);
            }}
          >
            <MoreHorizIcon style={{ fontSize: 30 }} />
          </IconButton>
        </Box>
      ))}
      <SlideUpMenu
        toggle={toggleSlideMenu}
        setToggle={setToggleSlideMenu}
        item={currentItem}
      />
    </Box>
  );
};

export default ListMobileView;
