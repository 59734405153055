import React, { useState } from "react";
import {
  Box,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  IconButton,

} from "@mui/material";
import Filters from "../components/Filters";
import ListMobileView from "../components/ListMobileView";

import ListIcon from "@mui/icons-material/ViewList";
import GridViewIcon from "@mui/icons-material/GridView";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import {
  ClikkleItems,
  RandomClikkleItems,
} from "../components/Constants/DummyData";
import ListFilesFolder from "../components/ListFilesFolder";
import TileFilesFolder from "../components/TileFilesFolder";
import Catelog from "../components/Catelog";
import { useMediaQuery } from "@mui/material";

const Favorite = () => {
  const [view, setView] = useState("list");

  const handleViewChange = (event, newView) => {
    console.log(newView);
    if (newView !== null) setView(newView);
  };
  const isMobile = useMediaQuery("(max-width:600px)");


  const tableHeadings = {
    name: true,
    owner: true,
    location: true,
    size: true,
    reason: false,
  };

  if (isMobile) {
    return (
      <Box
      sx={{
        padding: "0.5rem",
        height: "calc(100% - 1rem)",
        overflow: "scroll",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "1rem",
          }}
        >
          Files
        </Typography>
        <Box>
          {view === "folder" ? (
            <IconButton onClick={() => handleViewChange(null, "list")}>
              <GridViewIcon />
            </IconButton>
          ) : (
            <IconButton onClick={() => handleViewChange(null, "folder")}>
              <ListIcon />
            </IconButton>
          )}
        </Box>
      </Box>
      <Box padding={"0.5rem"}>
        {view === "list" ? (
          <ListMobileView
            items={[...RandomClikkleItems, ...RandomClikkleItems]}
          />
        ) : (
          <TileFilesFolder
            items={[...RandomClikkleItems, ...RandomClikkleItems]}
          />
        )}
      </Box>
    </Box>
    )
  }

  return (
    <Box p={"1rem"}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        width="100%"
        gap={'1rem'}
        sx={{
          height: "calc(100vh - 8rem)",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            display: "none", // For Chrome, Safari, and Edge
          },
        }}
      >
        <Box display={"flex"} justifyContent={"space-between"} width={"100%"}>
          <Typography fontSize={"2rem"}>Favorite</Typography>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="end"
            alignItems="end"
            gap={"1rem"}
          >
            <ToggleButtonGroup
              value={view}
              exclusive
              onChange={handleViewChange}
              aria-label="view selection"
            >
              <ToggleButton
                value="list"
                aria-label="list view"
                sx={{
                  color: "#3767B1",
                  paddingX: "1rem",
                  border: "2px solid #3B84D9",
                  borderRadius: "20rem",
                  "&.Mui-selected": {
                    color: "white",
                    backgroundColor: "#3B84D9",
                  },
                }}
              >
                <ListIcon fontSize="1rem" />
              </ToggleButton>
              <ToggleButton
                value="folder"
                aria-label="folder view"
                sx={{
                  color: "#3767B1",
                  paddingX: "1rem",
                  border: "2px solid #3B84D9",
                  borderRadius: "20rem",
                  "&.Mui-selected": {
                    color: "white",
                    backgroundColor: "#3B84D9",
                  },
                }}
              >
                {view === "folder" ? (
                  <GridViewRoundedIcon fontSize="1rem" />
                ) : (
                  <GridViewIcon fontSize="1rem" />
                )}
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Box>
        <Filters leftImg={false} />

        {view === "list" && (
          <Box
            display={"flex"}
            width={"100%"}
            sx={{
              maxHeight: "calc(100vh - 8rem)",
              overflowY: "auto",
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              gap="1rem"
              sx={{
                width: "100%", // Set the width
                maxHeight: "82vh", // Set the maximum height
                overflowY: "auto",
                paddingTop: "1rem",
                // Enable vertical scrolling
                "&::-webkit-scrollbar": {
                  display: "none", // For Chrome, Safari, and Edge
                }, // Enable vertical scrolling
              }}
            >
              <ListFilesFolder
                items={RandomClikkleItems}
                tableHeadings={tableHeadings}
              />
            </Box>
          </Box>
        )}
        {view === "folder" && (
          <Box
            display="flex"
            flexDirection="column"
            gap="1rem"
            sx={{
              width: "100%", // Set the width
              paddingTop: "1rem",
              maxHeight: "calc(100vh - 8rem)",
              overflowY: "auto",
              // Enable vertical scrolling
              "&::-webkit-scrollbar": {
                display: "none", // For Chrome, Safari, and Edge
              }, // Enable vertical scrolling
            }}
          >
            <Box>
              <Typography
                variant="body"
                fontFamily="sans-serif"
                fontWeight="700"
              >
                Folder
              </Typography>
              <Catelog items={ClikkleItems} />
            </Box>
            <Box>
              <Typography
                variant="body"
                fontFamily="sans-serif"
                fontWeight="700"
              >
                File
              </Typography>
              <TileFilesFolder items={RandomClikkleItems} />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Favorite;
