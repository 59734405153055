import {
    DriveFileRenameOutlineOutlined,
    ShareOutlined,
    LinkOutlined,
    DownloadOutlined,
    FavoriteBorderOutlined,
    StarOutlineOutlined,
    DeleteOutlineOutlined,
    InfoOutlined,
    MoveToInboxOutlined,
    FileCopyOutlined,
    AddToDriveOutlined,
    OfflinePinOutlined,
  } from "@mui/icons-material";
  

export const NonClikkleItems = [
    {
        type: 'folder',
        name: 'Logos',
        location: '/design',
        owner: 'Me',
        size: '100MB',
        reason: 'Contains company logos',
        clikkle: false,
        last_modified: "Jan 4, 2025",
    },
    {
        type: 'folder',
        name: 'Assets',
        location: '/media',
        owner: 'Me',
        size: '1GB',
        reason: 'Assets for marketing campaigns',
        clikkle: false,
        last_modified: "Jan 4, 2025",
    },
    {
        type: 'file',
        name: 'Files',
        location: '/documents',
        owner: 'Me',
        size: '500KB',
        reason: 'Important company files',
        clikkle: false,
        last_modified: "Jan 4, 2025",
    },
    {
        type: 'folder',
        name: 'Startup Screens',
        location: '/projects/startup',
        owner: 'Me',
        size: '200MB',
        reason: 'Screens for startup sequence',
        clikkle: false,
        last_modified: "Jan 4, 2025",
    },
    {
        type: 'folder',
        name: 'Head Screens',
        location: '/projects/ui',
        owner: 'Me',
        size: '300MB',
        reason: 'UI screens for header section',
        clikkle: false,
        last_modified: "Jan 4, 2025",
    },
    {
        type: 'folder',
        name: 'Bottom Screens',
        location: '/projects/ui',
        owner: 'Me',
        size: '250MB',
        reason: 'UI screens for footer section',
        clikkle: false,
        last_modified: "Jan 4, 2025",

    },    {
        type: 'folder',
        name: 'Assets',
        location: '/media',
        owner: 'Me',
        size: '1GB',
        reason: 'Assets for marketing campaigns',
        clikkle: false,
        last_modified: "Jan 4, 2025",
    },
    {
        type: 'file',
        name: 'Files',
        location: '/documents',
        owner: 'Me',
        size: '500KB',
        reason: 'Important company files',
        clikkle: false,
        last_modified: "Jan 4, 2025",
    },
    {
        type: 'folder',
        name: 'Startup Screens',
        location: '/projects/startup',
        owner: 'Me',
        size: '200MB',
        reason: 'Screens for startup sequence',
        clikkle: false,
        last_modified: "Jan 4, 2025",
    },
    {
        type: 'folder',
        name: 'Head Screens',
        location: '/projects/ui',
        owner: 'Me',
        size: '300MB',
        reason: 'UI screens for header section',
        clikkle: false,
        last_modified: "Jan 4, 2025",
    },
    {
        type: 'folder',
        name: 'Bottom Screens',
        location: '/projects/ui',
        owner: 'Me',
        size: '250MB',
        reason: 'UI screens for footer section',
        clikkle: false,
        last_modified: "Jan 4, 2025",

    },    {
        type: 'folder',
        name: 'Assets',
        location: '/media',
        owner: 'Me',
        size: '1GB',
        reason: 'Assets for marketing campaigns',
        clikkle: false,
        last_modified: "Jan 4, 2025",
    },
    {
        type: 'file',
        name: 'Files',
        location: '/documents',
        owner: 'Me',
        size: '500KB',
        reason: 'Important company files',
        clikkle: false,
        last_modified: "Jan 4, 2025",
    },
    {
        type: 'folder',
        name: 'Startup Screens',
        location: '/projects/startup',
        owner: 'Me',
        size: '200MB',
        reason: 'Screens for startup sequence',
        clikkle: false,
        last_modified: "Jan 4, 2025",
    },
    {
        type: 'folder',
        name: 'Head Screens',
        location: '/projects/ui',
        owner: 'Me',
        size: '300MB',
        reason: 'UI screens for header section',
        clikkle: false,
        last_modified: "Jan 4, 2025",
    },
    {
        type: 'folder',
        name: 'Bottom Screens',
        location: '/projects/ui',
        owner: 'Me',
        size: '250MB',
        reason: 'UI screens for footer section',
        clikkle: false,
        last_modified: "Jan 4, 2025",

    },    {
        type: 'folder',
        name: 'Assets',
        location: '/media',
        owner: 'Me',
        size: '1GB',
        reason: 'Assets for marketing campaigns',
        clikkle: false,
        last_modified: "Jan 4, 2025",
    },
    {
        type: 'file',
        name: 'Files',
        location: '/documents',
        owner: 'Me',
        size: '500KB',
        reason: 'Important company files',
        clikkle: false,
        last_modified: "Jan 4, 2025",
    },
    {
        type: 'folder',
        name: 'Startup Screens',
        location: '/projects/startup',
        owner: 'Me',
        size: '200MB',
        reason: 'Screens for startup sequence',
        clikkle: false,
        last_modified: "Jan 4, 2025",
    },
    {
        type: 'folder',
        name: 'Head Screens',
        location: '/projects/ui',
        owner: 'Me',
        size: '300MB',
        reason: 'UI screens for header section',
        clikkle: false,
        last_modified: "Jan 4, 2025",
    },
    {
        type: 'folder',
        name: 'Bottom Screens',
        location: '/projects/ui',
        owner: 'Me',
        size: '250MB',
        reason: 'UI screens for footer section',
        clikkle: false,
        last_modified: "Jan 4, 2025",

    },    {
        type: 'folder',
        name: 'Assets',
        location: '/media',
        owner: 'Me',
        size: '1GB',
        reason: 'Assets for marketing campaigns',
        clikkle: false,
        last_modified: "Jan 4, 2025",
    },
    {
        type: 'file',
        name: 'Files',
        location: '/documents',
        owner: 'Me',
        size: '500KB',
        reason: 'Important company files',
        clikkle: false,
        last_modified: "Jan 4, 2025",
    },
    {
        type: 'folder',
        name: 'Startup Screens',
        location: '/projects/startup',
        owner: 'Me',
        size: '200MB',
        reason: 'Screens for startup sequence',
        clikkle: false,
        last_modified: "Jan 4, 2025",
    },
    {
        type: 'folder',
        name: 'Head Screens',
        location: '/projects/ui',
        owner: 'Me',
        size: '300MB',
        reason: 'UI screens for header section',
        clikkle: false,
        last_modified: "Jan 4, 2025",
    },
    {
        type: 'folder',
        name: 'Bottom Screens',
        location: '/projects/ui',
        owner: 'Me',
        size: '250MB',
        reason: 'UI screens for footer section',
        clikkle: false,
        last_modified: "Jan 4, 2025",

    },    {
        type: 'folder',
        name: 'Assets',
        location: '/media',
        owner: 'Me',
        size: '1GB',
        reason: 'Assets for marketing campaigns',
        clikkle: false,
        last_modified: "Jan 4, 2025",
    },
    {
        type: 'file',
        name: 'Files',
        location: '/documents',
        owner: 'Me',
        size: '500KB',
        reason: 'Important company files',
        clikkle: false,
        last_modified: "Jan 4, 2025",
    },
    {
        type: 'folder',
        name: 'Startup Screens',
        location: '/projects/startup',
        owner: 'Me',
        size: '200MB',
        reason: 'Screens for startup sequence',
        clikkle: false,
        last_modified: "Jan 4, 2025",
    },
    {
        type: 'folder',
        name: 'Head Screens',
        location: '/projects/ui',
        owner: 'Me',
        size: '300MB',
        reason: 'UI screens for header section',
        clikkle: false,
        last_modified: "Jan 4, 2025",
    },
    {
        type: 'folder',
        name: 'Bottom Screens',
        location: '/projects/ui',
        owner: 'Me',
        size: '250MB',
        reason: 'UI screens for footer section',
        clikkle: false,
        last_modified: "Jan 4, 2025",
    },
    {
        type: 'folder',
        name: 'Assets',
        location: '/media',
        owner: 'Me',
        size: '1GB',
        reason: 'Assets for marketing campaigns',
        clikkle: false,
        last_modified: "Jan 4, 2025",
    },
    {
        type: 'file',
        name: 'Files',
        location: '/documents',
        owner: 'Me',
        size: '500KB',
        reason: 'Important company files',
        clikkle: false,
        last_modified: "Jan 4, 2025",
    },
    {
        type: 'folder',
        name: 'Startup Screens',
        location: '/projects/startup',
        owner: 'Me',
        size: '200MB',
        reason: 'Screens for startup sequence',
        clikkle: false,
        last_modified: "Jan 4, 2025",
    },
    {
        type: 'folder',
        name: 'Head Screens',
        location: '/projects/ui',
        owner: 'Me',
        size: '300MB',
        reason: 'UI screens for header section',
        clikkle: false,
        last_modified: "Jan 4, 2025",
    },
    {
        type: 'folder',
        name: 'Bottom Screens',
        location: '/projects/ui',
        owner: 'Me',
        size: '250MB',
        reason: 'UI screens for footer section',
        clikkle: false,
        last_modified: "Jan 4, 2025",

    },
]


export const RandomClikkleItems = [
    {
      type: "file",
      name: "Project Docs",
      location: "Files",
      owner: "alice@example.com",
      size: "450KB",
      reason: "You opened • Dec 18, 2024",
      clikkle: false,
      img: "https://via.placeholder.com/150/FFB6C1/000000?text=Docs",
      
        last_modified: "Jan 4, 2025",
    },
    {
      type: "file",
      name: "Presentation Slides",
      location: "Files",
      owner: "alice@example.com",
      size: "2MB",
      reason: "You opened • Dec 18, 2024",
      clikkle: false,
      img: "https://via.placeholder.com/150/87CEEB/000000?text=Slides",
      last_modified: "Jan 4, 2025",

    },
    {
      type: "file",
      name: "Marketing Assets",
      location: "Clikkle Design Materials",
      owner: "bob@example.com",
      size: "2GB",
      reason: "You opened • Dec 18, 2024",
      clikkle: false,
      img: "https://via.placeholder.com/150/FF6347/000000?text=Assets",
      last_modified: "Jan 4, 2025",

    },
    {
      type: "file",
      name: "UI Mockups",
      location: "Clikkle Design Materials",
      owner: "charlie@example.com",
      size: "1.5GB",
      reason: "Owned by me",
      clikkle: false,
      img: "https://via.placeholder.com/150/4682B4/FFFFFF?text=Mockups",
      last_modified: "Jan 4, 2025",

    },
    {
      type: "file",
      name: "Financial Reports",
      location: "Clikkle Hr",
      owner: "diana@example.com",
      size: "300KB",
      reason: "Updated yesterday",
      clikkle: false,
      img: "https://via.placeholder.com/150/32CD32/FFFFFF?text=Reports",
      last_modified: "Jan 4, 2025",

    },
    {
      type: "file",
      name: "Codebase",
      location: "Files",
      owner: "eve@example.com",
      size: "10MB",
      reason: "Shared in 'Team Folder'",
      clikkle: false,
      img: "https://via.placeholder.com/150/FFD700/000000?text=Code",
      last_modified: "Jan 4, 2025",

    },
    {
      type: "file",
      name: "Project Plan",
      location: "Files",
      owner: "alice@example.com",
      size: "1MB",
      reason: "Starred by me",
      clikkle: false,
      img: "https://via.placeholder.com/150/FF8C00/FFFFFF?text=Plan",
      last_modified: "Jan 4, 2025",

    },
    {
      type: "file",
      name: "Event Photos",
      location: "Folder",
      owner: "bob@example.com",
      size: "3GB",
      reason: "You opened • Dec 18, 2024",
      clikkle: false,
      img: "https://via.placeholder.com/150/8A2BE2/FFFFFF?text=Photos",
      last_modified: "Jan 4, 2025",

    },
    {
      type: "file",
      name: "Legal Documents",
      location: "Clikkle Hr",
      owner: "diana@example.com",
      size: "200KB",
      reason: "Needs review",
      clikkle: false,
      img: "https://via.placeholder.com/150/800000/FFFFFF?text=Legal",
      last_modified: "Jan 4, 2025",

    },
    {
      type: "file",
      name: "Training Materials",
      location: "Clikkle Design Materials",
      owner: "charlie@example.com",
      size: "15MB",
      reason: "Shared with my team",
      clikkle: false,
      img: "https://via.placeholder.com/150/2E8B57/FFFFFF?text=Training",
      last_modified: "Jan 4, 2025",

    },
  ];
  
  
  export const ClikkleItems = [
    {
        type: 'folder',
        name: 'Clikkle Design Materials',
        location: 'My Files',
        owner: 'Me',
        size: '150MB',
        reason: 'Design materials for Clikkle',
        clikkle: true,
        last_modified: "Jan 4, 2025",

    },
    {
        type: 'folder',
        name: 'Clikkle HR',
        location: 'Shared with me',
        owner: 'Me',
        size: '50MB',
        reason: 'HR-related documents for Clikkle',
        clikkle: true
        ,
        last_modified: "Jan 4, 2025",
    },
    {
        type: 'folder',
        name: 'Clikkle HR',
        location: 'Shared with me',
        owner: 'Me',
        size: '50MB',
        reason: 'HR-related documents for Clikkle',
        clikkle: true,
        last_modified: "Jan 4, 2025",
    },
    {
        type: 'folder',
        name: 'Clikkle Design Materials',
        location: 'Shared By me',
        owner: 'Me',
        size: '150MB',
        reason: 'Design materials for Clikkle',
        clikkle: true,
        last_modified: "Jan 4, 2025",
    },
    {
        type: 'folder',
        name: 'Clikkle HR',
        location: 'My Files',
        owner: 'Me',
        size: '50MB',
        reason: 'HR-related documents for Clikkle',
        clikkle: true,
        last_modified: "Jan 4, 2025",
    }
];

export const slideMenuOptions = [
    {
      label: "Rename",
      icon: <DriveFileRenameOutlineOutlined />,
      action: "rename",
    },
    {
      label: "Share",
      icon: <ShareOutlined />,
      action: "share",
    },
    {
      label: "Get link",
      icon: <LinkOutlined />,
      action: "get_link",
    },
    {
      label: "Download",
      icon: <DownloadOutlined />,
      action: "download",
    },
    {
      label: "Add to favorites",
      icon: <FavoriteBorderOutlined />,
      action: "add_to_favorites",
    },
    {
      label: "Star",
      icon: <StarOutlineOutlined />,
      action: "star",
    },
    {
      label: "Move to",
      icon: <MoveToInboxOutlined />,
      action: "move_to",
    },
    {
      label: "Make a copy",
      icon: <FileCopyOutlined />,
      action: "make_copy",
    },
    {
      label: "Add to My Drive",
      icon: <AddToDriveOutlined />,
      action: "add_to_drive",
    },
    {
      label: "Available offline",
      icon: <OfflinePinOutlined />,
      action: "available_offline",
    },
    {
      label: "Details & Activity",
      icon: <InfoOutlined />,
      action: "details_activity",
    },
    {
      label: "Remove",
      icon: <DeleteOutlineOutlined />,
      action: "remove",
    },
  ];