import ClikkleAds from "../assets/ClikkleFavicons/Clikkle Ads favicon.png";
import ClikkleMail from "../assets/ClikkleFavicons/Clikkle Mail-01.png";
import ClikkleCampaigns from "../assets/ClikkleFavicons/Clikkle Campaigns favicon.png";
import ClikkleEsign from "../assets/ClikkleFavicons/Clikkle E-sign-favicon.png";
import ClikkleFiles from "../assets/ClikkleFavicons/Clikkle Files favicon.png";
import hr from "../assets/ClikkleFavicons/hr.png";
import ClikkleHost from "../assets/ClikkleFavicons/Clikkle Host-favicon.png";
import ClikklePitch from "../assets/ClikkleFavicons/Clikkle Pitch-favicon.png";
import ClikkleProject from "../assets/ClikkleFavicons/Clikkle Projects-01.png";
import ClikkleLaunch from "../assets/ClikkleFavicons/Clikkle Launch favicon.png";

export const clikkleApps = [
    {
      name: "Ads",
      url: "https://ads.clikkle.com/",
      logo: ClikkleAds,
    },
    {
      name: "Campaigns",
      url: "https://campaigns.clikkle.com/",
      logo: ClikkleCampaigns,
    },
    {
      name: "E-Sign",
      url: "https://esign.clikkle.com/",
      logo: ClikkleEsign,
    },
    {
      name: "Files",
      url: "https://files.clikkle.com/",
      logo: ClikkleFiles,
    },
    {
        name: "HrIcon",
        url: "https://hr.clikkle.com/",
        logo: hr,
    },
    {
      name: "Host",
      url: "https://host.clikkle.com/",
      logo: ClikkleHost,
    },
    {
      name: "Launch",
      url: "https://launch.clikkle.com/",
      logo: ClikkleLaunch,
    },
    {
      name: "C-Mail",
      url: "https://mail.clikkle.com/",
      logo: ClikkleMail,
    },
    {
      name: "Pitch",
      url: "https://pitch.clikkle.com/",
      logo: ClikklePitch,
    },
  
    {
      name: "Projects",
      url: "https://projects.clikkle.com/",
      logo: ClikkleProject,
    },
  ];