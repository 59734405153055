import React, { useState } from "react";
import {
  Box,
  Fab,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const FloatingActionButton = () => {
  const [isActive, setIsActive] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setIsActive((prev) => !prev);

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setIsActive((prev) => !prev);

    setAnchorEl(null);
  };

  return (
    <Box
      style={{
        position: "fixed",
        bottom: "20px",
        right: "20px",
        marginRight: "50px",
        marginBottom: "10px",
      }}
    >
      <Fab
        color="primary"
        aria-label="add"
        onClick={handleClick}
        sx={{
          transition: "transform 0.3s ease",
          "& .MuiSvgIcon-root": {
            transition: "transform 0.3s ease",
            transform: isActive ? "rotate(45deg)" : "rotate(0deg)",
          },
        }}
      >
        <AddIcon />
      </Fab>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <InsertDriveFileIcon fontSize="small" sx={{ color: "#3379C7" }} />
          </ListItemIcon>
          <ListItemText>New File</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <CloudUploadIcon fontSize="small" sx={{ color: "#3379C7" }} />
          </ListItemIcon>
          <ListItemText>Upload File</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <CreateNewFolderIcon fontSize="small" sx={{ color: "#3379C7" }} />
          </ListItemIcon>
          <ListItemText>Create Folder</ListItemText>
        </MenuItem>

        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <MoreVertIcon fontSize="small" sx={{ color: "#3379C7" }} />
          </ListItemIcon>
          <ListItemText>More</ListItemText>
        </MenuItem>
      </Menu>

    </Box>
  );
};

export default FloatingActionButton;
