import React, { useState } from "react";
import {
  Box,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Typography,
} from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import PersonIcon from "@mui/icons-material/Person";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CloudIcon from "@mui/icons-material/Cloud";
import ImageIcon from "@mui/icons-material/Image";
import MovieIcon from "@mui/icons-material/Movie";
import DescriptionIcon from "@mui/icons-material/Description";
import FolderIcon from "@mui/icons-material/Folder";
import AudioFileIcon from "@mui/icons-material/AudioFile";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const Filters = ({ leftImg }) => {
  const [anchorEls, setAnchorEls] = useState({
    type: null,
    people: null,
    modified: null,
    source: null,
  });

  const [selectedFilters, setSelectedFilters] = useState({
    type: null,
    people: null,
    modified: null,
    source: null,
  });

  const handleMenuOpen = (event, category) => {
    setAnchorEls({ ...anchorEls, [category]: event.currentTarget });
  };

  const handleMenuClose = (category) => {
    setAnchorEls({ ...anchorEls, [category]: null });
  };

  const handleFilterSelect = (category, value) => {
    setSelectedFilters({ ...selectedFilters, [category]: value });
    handleMenuClose(category);
  };

  const handleFilterClear = (category) => {
    setSelectedFilters({ ...selectedFilters, [category]: null });
  };

  const filters = {
    type: [
      {
        label: "Documents",
        icon: <DescriptionIcon style={{ color: "#2196f3" }} />,
        value: "documents",
      },
      {
        label: "Folders",
        icon: <FolderIcon style={{ color: "#ffca28" }} />,
        value: "folders",
      },
      {
        label: "Images",
        icon: <ImageIcon style={{ color: "#f44336" }} />,
        value: "images",
      },
      {
        label: "Videos",
        icon: <MovieIcon style={{ color: "#4caf50" }} />,
        value: "videos",
      },
      {
        label: "Audio",
        icon: <AudioFileIcon style={{ color: "#9c27b0" }} />,
        value: "audio",
      },
    ],
    people: [
      {
        label: "Me",
        icon: <PersonIcon style={{ color: "#2196f3" }} />,
        name: "Rishabh Raj Tarun",
        email: "rishabh@clikkle.com",
        value: "me",
      },
      {
        label: "Assigned to me",
        icon: <PersonIcon style={{ color: "#4caf50" }} />,
        name: "John Doe",
        email: "john.doe@example.com",
        value: "assigned",
      },
      {
        label: "Owned by me",
        icon: <PersonIcon style={{ color: "#ffc107" }} />,
        name: "Alice Smith",
        email: "alice.smith@example.com",
        value: "owned",
      },
      {
        label: "Collaborator",
        icon: <PersonIcon style={{ color: "#673ab7" }} />,
        name: "Mark Johnson",
        email: "mark.johnson@example.com",
        value: "collaborator",
      },
    ],

    modified: [
      { label: "Today", icon: <AccessTimeIcon />, value: "today" },
      { label: "Last 7 days", icon: <AccessTimeIcon />, value: "week" },
      { label: "Last 30 days", icon: <AccessTimeIcon />, value: "month" },
    ],
    location: [
      { label: "My Files", icon: <CloudIcon />, value: "myDrive" },
      { label: "Shared Drives", icon: <CloudIcon />, value: "sharedDrive" },
    ],
  };

  const showIcon = (type) => {
    switch (type) {
      case "type":
        return <InsertDriveFileOutlinedIcon />;
      case "people":
        return <PermIdentityOutlinedIcon />;
      case "modified":
        return <CalendarTodayOutlinedIcon />;
      case "location":
        return <FolderOpenOutlinedIcon />;
      default:
        return;
    }
  };

  return (
    <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
      {Object.keys(filters).map((category) => (
        <Box key={category}>
          <Button
            variant="outlined"
            startIcon={
              selectedFilters[category] || !leftImg ? null : showIcon(category)
            }
            endIcon={selectedFilters[category] ? null : <ArrowDropDownIcon />}
            onClick={(e) => handleMenuOpen(e, category)}
            sx={{
              height: 40,
              minWidth: 120,
              borderRadius: 6,
              backgroundColor: selectedFilters[category]
                ? "#5f6368"
                : "custom.search.main",
              color: selectedFilters[category] ? "white" : "#757575",
              borderColor: "transparent",
              display: "flex",
              justifyContent: selectedFilters[category]
                ? "space-between"
                : "center",
              alignItems: "center",
              padding: selectedFilters[category] ? 0 : "0 16px",
              "&:hover": {
                backgroundColor: "custom.search.focus",
                borderColor: "transparent",
              },
            }}
          >
            {selectedFilters[category] ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flex: 1,
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    padding: "0 10px",
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                    borderRight: "1px solid #ccc",
                    height: "100%",
                  }}
                >
                  {selectedFilters[category]}
                </Box>
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleFilterClear(category);
                  }}
                  sx={{
                    height: "100%",
                    borderRadius: 0,
                    padding: "0 10px",
                    color: "white",
                    backgroundColor: "transparent",
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Box>
            ) : (
              category.charAt(0).toUpperCase() + category.slice(1)
            )}
          </Button>
          <Menu
            anchorEl={anchorEls[category]}
            open={Boolean(anchorEls[category])}
            onClose={() => handleMenuClose(category)}
          >
            {category !== "people" &&
              filters[category].map((filter) => (
                <MenuItem
                  key={filter.value}
                  onClick={() => handleFilterSelect(category, filter.label)}
                >
                  <ListItemIcon>{filter.icon}</ListItemIcon>
                  <ListItemText primary={filter.label} />
                </MenuItem>
              ))}

            {category === "people" &&
              filters[category].map((filter) => (
                <MenuItem
                  key={filter.value}
                  onClick={() => handleFilterSelect(category, filter.name)}
                >
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    gap={"1rem"}
                    justifyContent={"space-between"}
                    width={'100%'}
                  >
                    <AccountCircle style={{ fontSize: 30, color: "gray" }} />

                    <Box>
                      <Typography variant="body1">{filter.name}</Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        whiteSpace={"nowrap"}
                        width={'10rem'}
                        overflow={'hidden'}
                        textOverflow={'ellipsis'}
                      >
                        {filter.email}
                      </Typography>
                    </Box>
                      <ArrowRightIcon />
                  </Box>
                </MenuItem>
              ))}
          </Menu>
        </Box>
      ))}
    </Box>
  );
};

export default Filters;
