import React, { useEffect, useState } from "react";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import { useNavigate, useLocation } from "react-router-dom";
import { useMediaQuery, Box } from "@mui/material";
import { useTheme } from "../style/theme";
import AppsIcon from "@mui/icons-material/Apps";
import HomeIcon from '@mui/icons-material/Home';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import StarIcon from '@mui/icons-material/Star';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import FolderIcon from '@mui/icons-material/Folder';

const BottomNavBar = () => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const showBottomNav = [
    "/",
    "/my-files",
    "/favorite",
    "/recent",
    "/shared-with-me",
  ].includes(location.pathname);
  console.log(useTheme());

  // Check if the screen size matches mobile view
  const isMobile = useMediaQuery("(max-width:600px)");
  const { mode } = useTheme();

  const [prevIsMobile, setPrevIsMobile] = useState(isMobile);

  // Handle navigation when viewport changes
  useEffect(() => {
    if (isMobile && !prevIsMobile) {
      navigate("/");
    } else if (!isMobile && prevIsMobile) {
      navigate("/");
    }
    setPrevIsMobile(isMobile);
  }, [isMobile, prevIsMobile, navigate]);



  const handleNavigation = (newValue) => {
    setValue(newValue);
    // Navigate to the corresponding route
    switch (newValue) {
      case 0:
        navigate("/");
        break;
      case 1:
        navigate("/favorite");
        break;
      case 2:
        window.location.href = "https://apps.clikkle.com";
        break;
      case 3:
        navigate("/shared-with-me");
        break;
      case 4:
        navigate("/my-files");
        break;

      default:
        break;
    }
  };
  useEffect(() => {
    switch (location.pathname) {
      case "/":
        setValue(0);
        break;
      case "/favorite":
        setValue(1);
        break;
      case "/my-files":
        setValue(4);
        break;
      case "/shared-with-me":
        setValue(3);
        break;
      default:
        break;
    }
  },[setValue, location, value]);

  if (!isMobile) {
    return null;
  }
  return (
    <Box>
      {showBottomNav && (
        <BottomNavigation
          value={value}
          onChange={(event, newValue) => handleNavigation(newValue)}
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            boxShadow: 3,
            zIndex: 1000,
            backgroundColor: mode === "light" ? "white" : "#1E1E1E",
          }}
        >
          <BottomNavigationAction
            sx={{
              color: mode === "light" ? "rgba(0, 0, 0, 0.6)" : "#A5A5A5",
            }}
            showLabel
            label="Recent"
            icon={
              <Box
                sx={{
                  paddingX: "1rem",
                  borderRadius: "1rem",
                  backgroundColor: value === 0 ? "#3767B133" : "transparent",
                }}
              >
                { value === 0 ? ( <HomeIcon  />):(<HomeOutlinedIcon/>)} 
              </Box>
            }
          />
          <BottomNavigationAction
            sx={{
              color: mode === "light" ? "rgba(0, 0, 0, 0.6)" : "#A5A5A5",
            }}
            showLabel
            label="Favorite"
            icon={
              <Box
                sx={{
                  paddingX: "1rem",
                  borderRadius: "1rem",
                  backgroundColor: value === 1 ? "#3767B133" : "transparent",
                }}
              >
                { value === 1 ?(<StarIcon/>): (<StarBorderOutlinedIcon />) }
              </Box>
            }
          />
          <BottomNavigationAction
            sx={{
              color: mode === "light" ? "rgba(0, 0, 0, 0.6)" : "#A5A5A5",
            }}
            showLabel
            label="Apps"
            icon={
              <Box
                sx={{
                  paddingX: "1rem",
                  borderRadius: "1rem",
                  backgroundColor: value === 2 ? "#3767B133" : "transparent",
                }}
              >
                <AppsIcon />
              </Box>
            }
          />
          <BottomNavigationAction
            sx={{
              color: mode === "light" ? "rgba(0, 0, 0, 0.6)" : "#A5A5A5",
            }}
            showLabel
            label="Shared"
            icon={
              <Box
                sx={{
                  paddingX: "1rem",
                  borderRadius: "1rem",
                  backgroundColor: value === 3 ? "#3767B133" : "transparent",
                }}
              >
                { value === 3 ?(<PeopleAltIcon />):( <PeopleAltOutlinedIcon/>)}
              </Box>
            }
          />
          <BottomNavigationAction
            sx={{
              color: mode === "light" ? "rgba(0, 0, 0, 0.6)" : "#A5A5A5",
            }}
            showLabel
            label="My Files"
            icon={
              <Box
                sx={{
                  paddingX: "1rem",
                  borderRadius: "1rem",
                  backgroundColor: value === 4 ? "#3767B133" : "transparent",
                }}
              >
                {value===4?(<FolderIcon/>):(<FolderOutlinedIcon />)}
              </Box>
            }
          />
        </BottomNavigation>
      )}
    </Box>
  );
};

export default BottomNavBar;
