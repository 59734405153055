import React, { useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  Card,
  CardMedia,
  ListItemIcon,
} from "@mui/material";
import {
  Folder,
  MoreVert,
  OpenInNew,
  FileDownload,
  Create,
  FileCopy,
  Share,
  Delete,
  FolderOutlined,
  AccountCircle,
} from "@mui/icons-material";
import {  
  
  Dialog, 
  DialogTitle, 
  DialogContent, 
  TextField,  
  Button, 
  Avatar 
} from "@mui/material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { useTheme } from "../style/theme";
import { useMediaQuery } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import amico from "../assets/Upgradehrimages/dummyfileimage.png";
import SlideUpMenu from "./SlideUpMenu";
import { Link, ArrowDropDown, Close } from "@mui/icons-material";
import { useUser } from '../hooks/Authorize';
import ProfileIcon from '../../src/assets/Icons/profile_temp.svg'
import ProfileIconOne from '../../src/assets/Icons/profile_temp1.svg'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';



const TileFilesFolder = ({ items, gridStyle = true }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [submenuOpen, setSubmenuOpen] = useState(null); // Track which submenu is open
  const isMobile = useMediaQuery("(max-width:600px)");

  const handleMenuOpen = (event, item) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSubmenuOpen(null); // Close submenu on menu close
  };

  const handleSubMenuToggle = (submenu) => {
    setSubmenuOpen(submenu === submenuOpen ? null : submenu); // Toggle submenu visibility
  };
  const [currentItem, setCurrentItem] = useState({});
  const isMenuOpen = Boolean(anchorEl);
  const [toggleSlideMenu, setToggleSlideMenu] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { mode } = useTheme();
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const platformUser = useUser();


  if (isMobile && gridStyle) {
    return (
      <Box
        display="grid"
        gap="1rem"
        width={"100%"}
        sx={{
          gridTemplateColumns: `repeat(auto-fit,${
            isMobile ? " minmax(7.5rem, 1fr))" : " minmax(15rem, 1fr))"
          }`,
        }}
      >
        {items.map((item, index) => (
          <Box
            key={index}
            paddingBottom={"1rem"}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent={"space-between"}
            gap={"1rem"}
            sx={{
              border: `2px solid ${
                mode === "light" ? "#e5e7eb" : "transparent"
              }`,
              borderRadius: "0.5rem",
            }}
          >
            {item.type === "file" ? (
              <Card sx={{ maxWidth: 300, borderRadius: 2, boxShadow: 3 }}>
                <CardMedia image={amico} sx={{ height: 100, width: 150 }} />
              </Card>
            ) : (
              <Box>
                {item.clikkle && item.type === "folder" && (
                  <Folder style={{ fontSize: 100, color: "#488BF1" }} />
                )}
                {!item.clikkle && item.type === "folder" && (
                  <Folder style={{ fontSize: 100, color: "#FFCA28" }} />
                )}
              </Box>
            )}

            <Box
              width="100%"
              display="flex"
              justifyContent="space-between"
              alignItems={"center"}
            >
              {item.type === "file" && (
                <InsertDriveFileIcon
                  style={{ fontSize: 20, color: "#FFCA28" }}
                />
              )}
              <Typography
                variant="body"
                fontFamily="sans-serif"
                fontWeight="500"
                lineHeight="2rem"
                whiteSpace={"nowrap"}
                textOverflow={"ellipsis"}
                overflow={"hidden"}
                sx={{
                  color: `${mode === "light" ? "rgba(0, 0, 0, 0.6)" : "#fff"} `,
                }}
              >
                {item.name}
              </Typography>
              <IconButton
                onClick={() => {
                  setCurrentItem(item);
                  setToggleSlideMenu(true);
                  console.log("toggle active", toggleSlideMenu);
                }}
                sx={{ color: "#A5A5A5", padding: "0" }}
              >
                <MoreHorizIcon style={{ fontSize: 30 }} />
              </IconButton>
            </Box>
          </Box>
        ))}
        <SlideUpMenu
          toggle={toggleSlideMenu}
          setToggle={setToggleSlideMenu}
          item={currentItem}
        />
      </Box>
    );
  }

  return (
    <Box
      display="grid"
      gap="1rem"
      padding="1rem"
      width={"100%"}
      sx={{
        gridTemplateColumns: `repeat(auto-fit, minmax(15rem, 1fr))`,
      }}
    >
      {items.map((item, index) => (
        <Box
          key={index}
          padding="1rem"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent={"space-between"}
          gap={"1rem"}
          sx={{
            backgroundColor: mode === "light" ? "white" : "#1E1E1E",
            border: `2px solid ${mode === "light" ? "#e5e7eb" : "transparent"}`,
            borderRadius: "0.5rem",
            ":hover": {
              backgroundColor: mode === "light" ? "#f7f7f7" : "#2E2E2E",
            },
          }}
        >
          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            alignItems={"center"}
          >
            {item.clikkle && item.type === "folder" && (
              <Folder style={{ fontSize: 30, color: "#488BF1" }} />
            )}
            {!item.clikkle && item.type === "folder" && (
              <Folder style={{ fontSize: 30, color: "#FFCA28" }} />
            )}

            {item.type === "file" && (
              <InsertDriveFileIcon style={{ fontSize: 30, color: "#FFCA28" }} />
            )}
            <Typography
              variant="body"
              fontFamily="sans-serif"
              fontWeight="500"
              lineHeight="2rem"
              whiteSpace={"nowrap"}
              textOverflow={"ellipsis"}
              overflow={"hidden"}
              sx={{
                color: `${mode === "light" ? "rgba(0, 0, 0, 0.6)" : "#fff"} `,
              }}
            >
              {item.name}
            </Typography>
            <IconButton
              onClick={(e) => handleMenuOpen(e, item)}
              sx={{ color: "#A5A5A5", padding: "0" }}
            >
              <MoreVert style={{ fontSize: 30 }} />
            </IconButton>
          </Box>

          <Card sx={{ maxWidth: 300, borderRadius: 2, boxShadow: 3 }}>
            <CardMedia
              image={amico}
              alt="File Preview"
              sx={{ height: 150, width: 200 }}
            />
          </Card>

          <Box width="100%" display="flex" gap={"1rem"} alignItems={"center"}>
            <AccountCircle style={{ fontSize: 30, color: "gray" }} />
            <Typography
              variant="body"
              fontFamily="sans-serif"
              fontWeight="500"
              lineHeight="2rem"
              whiteSpace={"nowrap"}
              sx={{
                color: `${mode === "light" ? "rgba(0, 0, 0, 0.6)" : "#fff"} `,
                fontSize: "12px",
              }}
            >
              {item.reason}
            </Typography>
          </Box>

          {/* Menu for More Options */}
          <Menu
            anchorEl={anchorEl}
            open={isMenuOpen}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            PaperProps={{
              sx: { width: "250px" }, // Increased width of the menu
            }}
          >
            <MenuItem onClick={() => handleSubMenuToggle("openWith")}>
              <ListItemIcon>
                <OpenInNew fontSize="small" />
              </ListItemIcon>
              Open With
            </MenuItem>
            {submenuOpen === "openWith" && (
              <Box>
                <MenuItem onClick={() => console.log("Open With File")}>
                  App 1
                </MenuItem>
                <MenuItem onClick={() => console.log("Open With File")}>
                  App 2
                </MenuItem>
              </Box>
            )}
            <Divider />
            <MenuItem onClick={() => console.log("Download")}>
              <ListItemIcon>
                <FileDownload fontSize="small" />
              </ListItemIcon>
              Download
            </MenuItem>
            <MenuItem onClick={() => console.log("Rename")}>
              <ListItemIcon>
                <Create fontSize="small" />
              </ListItemIcon>
              Rename
            </MenuItem>
            <MenuItem onClick={() => console.log("Make a Copy")}>
              <ListItemIcon>
                <FileCopy fontSize="small" />
              </ListItemIcon>
              Make a Copy
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => console.log("Move to Trash")}>
              <ListItemIcon>
                <Delete fontSize="small" />
              </ListItemIcon>
              Move to Trash
            </MenuItem>
            <MenuItem onClick={() => console.log("Not Helpful")}>
              <ListItemIcon>
                <Delete fontSize="small" />
              </ListItemIcon>
              Not Helpful
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleOpenModal}>
              <ListItemIcon>
                <Share fontSize="small" />
              </ListItemIcon>
              Share
            </MenuItem>
            {/* {submenuOpen === "share" && (
              <Box>
                <MenuItem onClick={handleOpenModal}>
                  Invite People
                </MenuItem>
                <MenuItem onClick={() => console.log("Copy link")}>
                  Copy Link
                </MenuItem>
              </Box>
            )} */}
            <Dialog 
              open={openModal} 
              onClose={handleCloseModal} 
              maxWidth="sm" 
              fullWidth
              sx={{
                "& .MuiBackdrop-root": {
                  backgroundColor: "rgba(0, 0, 0, 0)", // Light transparent overlay
                },
                
              }}
              >
              <DialogTitle 
                sx={{ 
                  display: "flex", 
                  justifyContent: "space-between", 
                  alignItems: "center", 
                  fontSize: "18px" 
                }}
              >
                Share '{item.name}'
                <Box sx={{ display: "flex", gap: 1 }}>
                  {/* <IconButton>
                    <HelpOutline />
                  </IconButton> */}
                  <Button
                    startIcon={<Link sx={{
                      transform: "rotate(135deg)"
                    }}/>}
                    sx={{  color: "rgb(55, 103, 177)" }}
                    onClick={() => console.log("Copy link clicked")}
                  >
                    Copy link
                  </Button>
                  <IconButton onClick={handleCloseModal}>
                    <Close />
                  </IconButton>
                </Box>
              </DialogTitle>
              <Divider/>

              <DialogContent>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,  // Adjust spacing between elements
                  marginBottom: "20px",
                  marginLeft: '-8px'
                }}
              >
                <TextField
                  
                  placeholder="Add people, groups and calendar event"
                  
                  sx={{
                    flex: 1,  
                    maxWidth: "90%",
                    width: "90%", 
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",
                      height: "40px", 
                      "& fieldset": {
                        borderColor: "#424242",
                      },
                    },
                    "& .MuiInputBase-input": {
                      padding: "8px 14px", 
                      color: 'rgb(86, 86, 86)'
                    },
                  }}
                />
                <Button 
                  variant="contained" 
                  onClick={handleCloseModal}
                  sx={{ 
                    width: "100px", 
                    backgroundColor: "rgb(55, 103, 177)", color: "white", borderRadius: "10px",
                    whiteSpace: "nowrap",
                    marginTop: '-18px'
                  }}
                >
                  Add
                </Button>
              </Box>

              <Typography variant="subtitle1" sx={{ fontWeight: "bold", marginBottom: 1 }}>
                Who has access
              </Typography>
              <Box 
                sx={{ 
                  display: "flex", 
                  alignItems: "center", 
                  gap: 2, 
                  marginBottom: "20px" ,
        
                }}
              >
                <Avatar sx={{ width: 32, height: 32 }} src={ProfileIcon}/>
                <Box>
                  <Typography variant="body1">{platformUser.firstName} {" "}{platformUser.lastName}{" "}(you)</Typography>
                  <Typography variant="body2" color="text.secondary">{item.owner}</Typography>
                </Box>
                <Typography sx={{ marginLeft: "auto" }}>Owner</Typography>
              </Box>
              <Box 
                sx={{ 
                  display: "flex", 
                  alignItems: "center", 
                  gap: 2, 
                  marginBottom: "20px" ,
        
                }}
                >
                <Avatar sx={{ width: 32, height: 32 }} src={ProfileIconOne}/>
                <Box>
                  <Typography variant="body1">{platformUser.firstName} {" "}{platformUser.lastName}</Typography>
                  <Typography variant="body2" color="text.secondary">{item.owner}</Typography>
                </Box>
                {/* <Typography sx={{ marginLeft: "auto", fontWeight: "bold" }}>Owner</Typography> */}
                <Box sx={{display:"flex", marginLeft: "auto", gap: 1}}>
                  <Typography>Can View</Typography>
                  <KeyboardArrowDownOutlinedIcon />
                </Box>
              </Box>
              <Box 
                sx={{ 
                  display: "flex", 
                  alignItems: "center", 
                  gap: 2, 
                  marginBottom: "20px" ,
        
                }}
              >
                <Avatar sx={{ width: 32, height: 32 }} src={ProfileIcon}/>
                <Box>
                  <Typography variant="body1">{platformUser.firstName} {" "}{platformUser.lastName}</Typography>
                  <Typography variant="body2" color="text.secondary">{item.owner}</Typography>
                </Box>
                {/* <Typography sx={{ marginLeft: "auto", fontWeight: "bold" }}>Owner</Typography> */}
                <Box sx={{display:"flex", marginLeft: "auto", gap: 1}}>
                  <Typography >Can View</Typography>
                  <KeyboardArrowDownOutlinedIcon/>
                </Box>
              </Box>

                <Typography variant="subtitle1" sx={{ fontWeight: "bold", marginBottom: 1 }}>
                  General access
                </Typography>
                {/* <Box>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold", marginBottom: 1 }}>John Doe</Typography>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold", marginBottom: 1 }} color="text.secondary">johndoe@example.com</Typography>
                  </Box> */}
                <Box 
                  sx={{ 
                    display: "flex", 
                    alignItems: "center", 
                    gap: 2, 
                    // border: "1px solid lightgray", 
                    // borderRadius: "8px", 
                    padding: "8px", 
                    marginBottom: "20px" 
                  }}
                >
                  {/* <HttpsOutlinedIcon sx={{ color: "lightgray" }} /> */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "40px",  // Adjust size as needed
                      height: "40px", 
                      borderRadius: "50%",  // Makes the border circular
                      backgroundColor: "rgb(59,59,59)",  // Fill with red color
                      marginLeft: "-10px"
                    }}
                  >
                    <HttpsOutlinedIcon sx={{ color: "white" }} />
                  </Box>
                  {/* <Typography>Restricted</Typography> */}
                  <Box>
                    {/* <Typography variant="body1">Restricted</Typography> */}
                    <Box sx={{display: "flex", alignItems: "center", gap: 1}}>
                      <Typography variant="body1">Restricted</Typography>
                      <Typography><ArrowDropDown sx={{ color: "white", cursor: "pointer" }} /></Typography>
                    </Box>
                    <Typography variant="body2" color="text.secondary">Only people with access can open with the link</Typography>
                    
                  </Box>
                  <IconButton sx={{ marginLeft: "auto" }}>
                    {/* <Search fontSize="small" /> */}
                  </IconButton>
                </Box>

                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "end", marginLeft:"80%" }}>
                  
                  <Button 
                    variant="contained" 
                    onClick={handleCloseModal}
                    sx={{ width: "100px", backgroundColor: "rgb(55, 103, 177)", color: "white", borderRadius: "10px", }}
                  >
                    Done
                  </Button>
                </Box>
              </DialogContent>
            </Dialog>

            <MenuItem onClick={() => console.log("Organize")}>
              <ListItemIcon>
                <FolderOutlined fontSize="small" />
              </ListItemIcon>
              Organize
            </MenuItem>
            <MenuItem onClick={() => console.log("Folder Information")}>
              <ListItemIcon>
                <FolderOutlined fontSize="small" />
              </ListItemIcon>
              Folder Information
            </MenuItem>
          </Menu>
        </Box>
      ))}
    </Box>
  );
};

export default TileFilesFolder;
