import React from "react";
import { Box, Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CropFreeIcon from '@mui/icons-material/CropFree';

const FloatingActions = () => {
  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 70,
        right: 30,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 1.5, // Space between buttons
        zIndex: '1'
      }}
    >
      {/* Scan Button */}
      <Fab
        aria-label="scan"
        sx={{
          mb: 1,
          bgcolor: "#E4E4E454",
          borderRadius: "7px",
          width: 56, // Square size
          height: 56,
          boxShadow: "none",
          backdropFilter: "blur(100px)",
          opacity:0.7,

          "&:hover": {
            bgcolor: "#E4E4E454", // Maintain same color on hover
          },
        }}
        onClick={() => alert("Scan feature clicked!")}
      >
        <CropFreeIcon 
        sx={{
            fontSize:'30px',
            color: 'white'
        }}
        />
      </Fab >

      {/* Add Button */}
      <Fab
        aria-label="add"
        sx={{
          bgcolor: "#3767B16B",
          borderRadius: "7px",
          width: 56, // Square size
          height: 56,
          boxShadow: "none",
          backdropFilter: "blur(100px)",
          opacity:0.7,

          "&:hover": {
            bgcolor: "#3767B16B", // Maintain same color on hover
          },
        }}
        onClick={() => alert("Add feature clicked!")}
      >
        <AddIcon sx={{
            fontSize:'40px',
            color: 'white'
        }}  />
      </Fab>
    </Box>
  );
};

export default FloatingActions;
